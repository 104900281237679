import {parseUriQuery} from '../web'
import {doWhen} from '../utils'
import {updateRequest, getRequestId} from '../dataLayer'
import {getCacheItem, setCacheItem} from '../cache'

const tuneCacheItem = 'tune_transaction'

function isTransactionIDSaved(ids, idCheck) {
  return ids.includes(idCheck)
}

function newTransactionID(cachedTune, requestID) {
  const query = parseUriQuery()
  if (!query || !query.transaction_id) {
    return ''
  }

  if (
    cachedTune &&
    isTransactionIDSaved(cachedTune.transaction_ids, query.transaction_id) &&
    requestID === cachedTune.request_id
  ) {
    return ''
  }

  return query.transaction_id
}

function updateCachedTune(tune, newID, requestID) {
  return {
    transaction_ids: tune && tune.transaction_ids ? [newID, ...tune.transaction_ids] : [newID],
    request_id: requestID,
  }
}

export function run() {
  doWhen(
    () => getRequestId(),
    () => {
      const requestID = getRequestId()
      const cachedTune = getCacheItem(tuneCacheItem)
      const transactionID = newTransactionID(cachedTune, requestID)
      if (!transactionID) {
        return
      }

      // append transaction id to existing cache, also needs requestID
      const newCachedTune = updateCachedTune(cachedTune, transactionID, requestID)

      updateRequest(
        requestID,
        {
          tune: {
            transaction_id: transactionID,
            request_id: requestID,
          },
        },
        () => setCacheItem(tuneCacheItem, newCachedTune),
      )
    },
  )
}
