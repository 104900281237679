import getPackageVersion from '../lib/getPackageVersion'
import * as googleData from '../lib/additional/googleData'
import * as GoogleClientId from '../lib/additional/GoogleClientId'
import * as googleGclData from '../lib/additional/googleGclData'
import * as Adobe from '../lib/additional/AdobeAnalytics'
import * as ErrorLog from '../lib/additional/ErrorLog'
import * as JsPing from '../lib/additional/JsPing'
import * as AdComData from '../lib/additional/AdComData'
import * as Adblock from '../lib/additional/AdblockCheck'
import * as Convert from '../lib/additional/Convert'
import * as DynamicPages from '../lib/additional/DynamicLandingPage'
import * as IncomingRequestId from '../lib/additional/IncomingRequestId'
import * as SmsOptIn from '../lib/additional/SmsOptIn'
import * as DatalayerBootstrap from '../lib/datalayerBootstrap'
import * as Mapi from '../lib/Mapi'
import * as DataLayer from '../lib/dataLayer'
import * as utils from '../lib/utils'
import * as FacebookId from '../lib/additional/FacebookId'
import * as BingData from '../lib/additional/BingData'
import * as CommissionJunction from '../lib/additional/CommissionJunction'
import * as Tune from '../lib/additional/Tune'
import * as Utm from '../lib/additional/Utm'
import * as googleSessionId from '../lib/additional/googleSessionId'
import * as AppendTrackingParams from '../lib/additional/AppendTrackingParams'
import * as ClSystem from '../lib/additional/ClSystem'
import * as pushlyData from '../lib/additional/pushlyData'
import * as visitorId from '../lib/additional/visitorId'
import * as vwoData from '../lib/additional/vwoData'

type RunCb = (u: boolean) => unknown

export type MapiUtils = {
  getRequestId: typeof Mapi.getRequestIdPromise
  getPromoCode: typeof DataLayer.getPromoCode
  doWhen: typeof utils.doWhenP
  datalayerEvent: typeof DataLayer.datalayerEvent
  getFromDataLayer: typeof DataLayer.getFromDataLayer
  updateRequestPriority: typeof Mapi.updateRequestPriority
  fetchTokenNumbers: typeof Mapi.fetchTokenNumbers
  bootstrap: typeof bootstrapModules
  updateRequest: typeof Mapi.updateRequestWhenAvailable
  version: typeof getPackageVersion
}

export function registerMapiUtils() {
  if (window.mapiUtils) return

  window.mapiUtils = {
    getRequestId: Mapi.getRequestIdPromise,
    getPromoCode: DataLayer.getPromoCode,
    doWhen: utils.doWhenP,
    datalayerEvent: DataLayer.datalayerEvent,
    getFromDataLayer: DataLayer.getFromDataLayer,
    updateRequestPriority: Mapi.updateRequestPriority,
    fetchTokenNumbers: Mapi.fetchTokenNumbers,
    bootstrap: bootstrapModules,
    updateRequest: Mapi.updateRequestWhenAvailable,
    version: getPackageVersion,
  }
}

export function runMapiModules(updated?: boolean, runCb?: RunCb) {
  registerMapiUtils()
  GoogleClientId.run()
  Adobe.run()
  JsPing.run()
  AdComData.run()
  Adblock.run()
  DatalayerBootstrap.run()
  Convert.run()
  DynamicPages.run()
  IncomingRequestId.run()
  SmsOptIn.run()
  FacebookId.run()
  BingData.run()
  CommissionJunction.run()
  Tune.run()
  Utm.run()
  AppendTrackingParams.run()
  ClSystem.run()
  googleData.run()
  googleGclData.run()
  googleSessionId.run()
  pushlyData.run()
  visitorId.run()
  vwoData.run()
  if (runCb) runCb.call(updated)
}

export function bootstrapModules(runCb?: RunCb) {
  Mapi.setupLegacy()

  if (!window.ranMapiModules) {
    ErrorLog.run(DataLayer.getRequestId)
    runMapiModules(false, runCb)
    window.runMapiModules = runMapiModules
    window.ranMapiModules = true
  }
}
