import * as Cache from './cache'
import {cookie, getStrippedDomain, postJSON} from './web'
import {
  CPRRequest,
  datalayerEvent,
  dataLayerEventEmitter,
  getPromoCode,
  getRequestId,
  handleMapiResponse,
  invalidateDataLayer,
  setDataLayerRequestId,
  setRequestId,
  updateForNewSession,
  updateRequest,
} from './dataLayer'
import {doWhen, doWhenP} from './utils'
import {getMapiUrl, debug, setEnv} from './Env'
import {RotationConfig} from './RotationConfig'

export async function updateRequestWhenAvailable(extraData) {
  return doWhenP(getRequestId).then(requestId => updateRequest(requestId, extraData))
}

export function getPromoCodePromise(timeout = 5000) {
  return doWhenP(getPromoCode, 200, timeout, false)
}

/**
 * Yes, this is hilarious, but deliberate. getRequestId has the side effect of invalidating the
 * whole cache if it detects a change between the cached requestId and the one in the data layer.
 */
export function checkForCacheInvalidation() {
  getRequestId()

  const promo = getPromoCode()
  const oldPromo = Cache.getCacheItem('lastPromo')

  if (!oldPromo) {
    Cache.setCacheItem('lastPromo', promo)
    return
  }

  if (promo && promo !== oldPromo) {
    debug('invalidating for mismatched promo', promo, oldPromo)
    invalidateDataLayer()
    updateForNewSession()
    Cache.setCacheItem('lastPromo', `${promo}`)
  }
}

export function getRequestData() {
  return Cache.getCacheItem('requestData')?.fullResponse
}

let rotation

export function handlePromo() {
  const promo = getPromoCode()
  setPromoCookie(promo)
}

/**
 * At some point, this will actually be configured.
 *
 * @returns {Promise}
 */
export async function trackPageView() {
  const trackEnabled = window.MAPI?.config?.trackEnabled ?? true
  if (!trackEnabled) return {}

  const mapiUrl = getMapiUrl('/cpr/external/track')
  const promo = rotation.promo()

  const reqData = {
    data: {
      request: {
        request_id: rotation.requestId(),
        brand: rotation.brand(),
        promo_code: promo,
        domain: rotation.domain(),
        path: rotation.path(),
      },
    },
  }

  const response = await postJSON(mapiUrl, reqData)
  return handleMapiResponse(response)
}

/**
 * Gets rotated numbers for the provided tokens.
 * @param {string[]} tokens
 * @param {RotationConfig|undefined} rotConfig
 */
export function fetchTokenNumbers(tokens, rotConfig = undefined) {
  return doWhenP(() => rotConfig || (window.MAPI && window.MAPI.Rotation)).then(() => {
    // eslint-disable-next-line no-param-reassign
    rotConfig = rotConfig || (window.MAPI && window.MAPI.Rotation)
    return new CPRRequest(rotConfig.promo(), rotConfig.brand(), rotConfig.domain(), rotConfig.requestId())
      .allowLastResortNumber(rotConfig.allowLastResort())
      .tokens(tokens)
      .send()
      .then(response => {
        dataLayerEventEmitter.emit('fetched-token-numbers', response ? response.tokens : [], this)
        return response
      })
  })
}

export function setupLegacy() {
  if (window.mapiRegistered) {
    return
  }

  checkForCacheInvalidation()
  window.MAPI = window.MAPI || {}
  rotation = window.MAPI.Rotation || new RotationConfig(window.MAPI?.config)
  window.MAPI.Rotation = rotation

  // Make sure we give WP sites a chance to load their request IDs
  doWhen(
    () => window.mapiEvents && window.mapiEvents.on,
    () => {
      window.mapiEvents.on('mapi_heartbeat', (_, response) => {
        debug('setting request ID from wordpress')
        if (!response || !response.requestId) return
        setRequestId(response.requestId)
      })
    },
    100,
    2500,
  )

  // Wait until we have a request ID before tracking the page view. But only
  // wait a bit; just track the page if we don't get one
  doWhen(
    () => getRequestId() || window.MAPI?.config,
    () => {
      trackPageView().then(() => {
        handlePromo()
      })
    },
    200,
    5000,
    true,
  )

  // Start looking for a request ID as soon as possible, trigger an event for the datalayer
  doWhenP(getRequestId, 100, 0).then(() => {
    setDataLayerRequestId(getRequestId())
    datalayerEvent({event: 'clDatalayerRequestId'})
  })

  window.mapiRegistered = true
}

function setupCommon(rotConfig) {
  const {environment} = rotConfig || {}
  setEnv(environment)
  checkForCacheInvalidation()
  window.MAPI = window.MAPI || {}
  window.MAPI.config = rotConfig
  rotation = new RotationConfig(rotConfig)
  window.MAPI.Rotation = rotation
}

/**
 *
 * @returns {*|Promise<T>}
 * @param rotConfig
 */
export function setup(rotConfig) {
  setupCommon(rotConfig)
  return trackPageView().then(r => {
    handlePromo()
    return r
  })
}

/**
 *
 * @returns {*|Promise<T>}
 * @param rotConfig
 */
export function setupSigil(rotConfig) {
  setupCommon(rotConfig)
  handlePromo()
}

function setPromoCookie(promo) {
  if (!promo) return

  if (promo !== cookie('promo')) {
    cookie('promo', promo, 60 * 60 * 24 * 30, '/', getStrippedDomain())
  }

  if (promo !== cookie('mapiJsPromo')) {
    cookie('mapiJsPromo', promo, 60 * 60 * 24 * 30, '/', getStrippedDomain())
  }
}

/**
 * Resolves to the current request ID when present
 */
export async function getRequestIdPromise(timeout = 5000) {
  return doWhenP(getRequestId, 200, timeout, false)
}

/**
 * Updates the current MAPI request with the given priority
 * @param {string|int} priority priority number
 * @returns {Promise<{}>} Resolves to the MAPI response
 */
export async function updateRequestPriority(priority) {
  const requestId = await getRequestIdPromise()
  return postJSON(getMapiUrl(`/cpr/external/request/${requestId}/priority`), {
    data: {
      request_id: requestId,
      priority,
    },
  })
}

export function replacePhoneNumbers() {
  return doWhenP(() => window.MAPI && window.MAPI.replacePhoneNumbers).then(window.MAPI.replacePhoneNumbers)
}

export function getFromCookies(name) {
  return cookie(name)
}
