import {getRequestId, updateRequest} from '../dataLayer'
import {parseUriQuery} from '../web'
import {doWhenP} from '../utils'
import {getCacheItem, setCacheItem} from '../cache'

export function run() {
  return doWhenP(() => getRequestId()).then(requestId => {
    const prevId = parseUriQuery().clreqid
    if (!prevId) {
      return
    }
    const prevSent = getCacheItem('previousRequestIds') || {}
    if (!prevId || prevSent[prevId]) {
      return
    }

    updateRequest(requestId, {
      linked_requests: {
        request_id: requestId,
        previous_request_id: prevId,
      },
    }).then(() => {
      const sent = getCacheItem('previousRequestIds') || {}
      sent[prevId] = true
      setCacheItem('previousRequestIds', sent)
    })
  })
}
