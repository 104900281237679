/**
 * Perform the 'action' function when 'predicate' returns a truthy value.
 * @param predicate The function to use to determine when the condition is met
 * @param action function The function to call after the condition is met
 * @param interval Interval to wait between predicate checks.
 * @param maxWait Maximum amount of time to wait for predicate to be true
 * @param callAfterExpiration Call the action after maxWait. true to call, false otherwise
 * @param onExpire A function to call if we reach max wait, not called if callAfterExpiration is true
 */
export function doWhen<T>(
  predicate: () => T,
  action: (t?: T) => void,
  interval = 500,
  maxWait: number = null,
  callAfterExpiration: boolean = false,
  onExpire: (e: Error) => void = null,
) {
  let waited = 0
  // eslint-disable-next-line no-param-reassign
  interval = interval || 500

  // If currently true, just call without a setInterval
  const initialResult = predicate()
  if (initialResult) {
    action(initialResult)
    return
  }

  const intId = setInterval(() => {
    waited += interval

    const result = predicate()
    if (result) {
      clearInterval(intId)
      action(result)
    }

    if (maxWait && waited >= maxWait) {
      clearInterval(intId)
      if (callAfterExpiration) action(result)
      else if (onExpire) onExpire(new Error('expiration'))
    }
  }, interval)
}

/**
 * Return a promise that resolves when 'predicate' returns a truthy value.
 *
 * @see doWhen
 */
export function doWhenP<T>(
  predicate: () => T,
  intervalArg = 500,
  maxWait: number = 0,
  callAfterExpiration: boolean = false,
): Promise<T> {
  return new Promise((resolve, reject) => {
    doWhen(predicate, resolve, intervalArg, maxWait, callAfterExpiration, reject)
  })
}

export function toArray(thing: any) {
  if (!Array.isArray(thing)) {
    if (thing) {
      if (typeof thing !== 'string' && typeof thing[Symbol.iterator] === 'function') {
        return Array.from(thing)
      }
      return [thing]
    }
    return []
  }
  return thing
}
