import {updateRequest, getRequestId} from '../dataLayer'
import {getCacheItem, setCacheItem} from '../cache'
import {doWhenP} from '../utils'

const CACHED_KEY = 'js_mark_request_id'

function handlePing() {
  const id = getRequestId()
  const cachedId = getCacheItem(CACHED_KEY)
  const reqBody = {
    js_mark: {request_id: id},
  }

  if (id === cachedId) return

  updateRequest(id, reqBody).then(() => {
    setCacheItem(CACHED_KEY, id)
  })
}

export function run() {
  return doWhenP(() => getRequestId())
    .then(handlePing)
    .catch(e => {
      console.log(`js ping request failed ${e}`)
    })
}
