import {getRequestId, updateRequest} from '../dataLayer'
import {parseUriQuery} from '../web'
import {doWhenP} from '../utils'

const OTHER_KEYS = ['kbid', 'loc1', 'loc2', 'device_type', 'device_model', 'creative']

/** @returns {Object} */
function getUtmData() {
  const query = parseUriQuery() || {}
  const data = {}

  Object.entries(query).forEach(([k, v]) => {
    if ((k.match(/^utm_/) && v.length) || (OTHER_KEYS.includes(k) && v.length)) {
      data[k] = v
    }
  })

  if (Object.keys(data).length) {
    data.query = window.window.location.search
  }

  return data
}

function updateUtmData() {
  const data = getUtmData()

  /** If we were referred from this site then don't send utm data */
  if (document.referrer.length) {
    const hostName = `${window.location.protocol}//${window.location.host}`
    if (document.referrer.indexOf(hostName) === 0) return
  }

  updateRequest(getRequestId(), {
    utm_data: data,
  })
}

/** @returns {boolean} */
function handlePredicate() {
  const data = getUtmData()
  return Object.keys(data).length && getRequestId()
}

/**
 * @param {number} [interval=5000]
 * @param {number} [timeout=30000]
 */
export function run(interval = 5000, timeout = 30000) {
  return doWhenP(handlePredicate, interval, timeout)
    .then(updateUtmData)
    .catch(e => {
      console.log(`utm request failed ${e}`)
    })
}
