/* eslint-disable no-underscore-dangle */
import {getRequestId, getPromoCode} from './dataLayer'
import EventEmitter from './EventEmitter'

export interface ReplacementConf {
  formatter?: ((s: string) => string) | string
  selectors?: string[] | string
  token?: string
  filters?: ((number: string, token: string, node: Node) => boolean)[]
  numbersToReplace?: string[]
  allowedTokens?: string[]
}

interface RotationConfigInput {
  brand?: string
  defaultToken?: string
  forceDomain?: string
  forcePath?: string
  promoCode?: string | number
  requestId?: string
  replacement?: ReplacementConf[]
  watchForNumbers?: boolean
  allowLastResortNumber?: boolean
}

// Used for the data required for rotation, without bringing in the full replacement
// code. This way, we can access the brand / promo / etc in a unified way from both the
// mapi-full and the rotation build
// eslint-disable-next-line import/prefer-default-export
export class RotationConfig {
  private conf: RotationConfigInput

  public events: EventEmitter

  constructor(config: RotationConfigInput) {
    this.conf = config || {}
    this.events = new EventEmitter()
  }

  setConfig(conf: RotationConfigInput) {
    this.conf = conf
  }

  brand(): string {
    return this.conf.brand || ''
  }

  defaultToken(): string {
    return this.conf.defaultToken || this.conf.brand
  }

  domain(): string {
    return this.conf.forceDomain || `${window.location.hostname}`.replace(/^www\./, '')
  }

  path(): string {
    return this.conf.forcePath || window.location.pathname
  }

  promo(): string {
    return this.conf.promoCode?.toString() || getPromoCode()
  }

  requestId(): string {
    return this.conf.requestId || getRequestId()
  }

  replacementConfig(): ReplacementConf[] {
    return this.conf.replacement || []
  }

  watchForReplacement(): boolean {
    return this.conf.watchForNumbers
  }

  get(key: string, defaultValue: any = null): any {
    return (this.conf as any)?.[key] ?? defaultValue
  }

  allowLastResort(): boolean {
    return typeof this.conf.allowLastResortNumber === 'undefined' ? false : this.conf.allowLastResortNumber
  }
}
