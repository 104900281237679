import {postJSON} from '../web'
import {error} from '../Env'

const url = 'https://lapi.clear.link/log'
const maxActive = 10
const maxSize = 65536
const maxSends = 20

let active = 0
let sends = 0

export function resetThrottle() {
  active = 0
  sends = 0
}

function log(message, level = 'ERROR', requestId = null) {
  // only allow x concurrent requests
  if (active >= maxActive) {
    return Promise.resolve({error: 'max concurrent exceeded'})
  }

  if (sends >= maxSends) {
    error('too many LAPI sends, not sending again')
    return Promise.resolve({error: 'max sends exceeded'})
  }

  active += 1
  sends += 1

  let msg = message
  if (typeof msg !== 'string') msg = JSON.stringify(msg)

  return postJSON(url, {
    msg: msg.substring(0, maxSize),
    level,
    requestId,
  })
    .then(resp => {
      active -= 1
      return resp
    })
    .catch(e => {
      active -= 1
      if (window.debugLapi) {
        console.error('error sending reports to lapi', e, msg)
      }
    })
}

export const lapi = {
  log: (msg, level, requestID) => log(msg, level, requestID),
  error: msg => log(msg, 'ERROR'),
  warning: msg => log(msg, 'WARNING'),
  notice: msg => log(msg, 'NOTICE'),
}

export function run(getRequestId) {
  window.onerror = function logError(msg, source, lineno, colno, err) {
    const r = getRequestId()
    const logMsg = err && err.stack ? err.stack : msg
    lapi.log(logMsg, 'ERROR', r)
  }
}
