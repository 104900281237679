import {getRequestIdPromise} from '../Mapi'
import {updateRequest} from '../dataLayer'
import {parseUriQuery} from '../web'
import {getCacheItem, setCacheItem} from '../cache'

export function run() {
  getRequestIdPromise()
    .then(requestId => {
      const {cl_system, cl_system_id, cl_scope, cl_link} = parseUriQuery()

      if (!cl_system || !cl_system_id) {
        return null
      }

      const cache = getCacheItem('cl_system')
      if (cache === cl_system + cl_system_id) {
        return null
      }

      return updateRequest(requestId, {
        cl: {
          request_id: requestId,
          cl_system,
          cl_system_id,
          ...(cl_scope && {cl_scope}),
          ...(cl_link && {cl_link}),
        },
      }).then(() => setCacheItem('cl_system', cl_system + cl_system_id))
    })
    .catch(e => console.log('timeout getting request id', e))
}
